class AuthError extends Error {
  /**
   * @param {string} [message]
   */
  constructor(message = "Not logged in") {
    super(`AuthError: ${message}`);
    this.name = "AuthError";
  }
}

export default AuthError;
