import { combineReducers } from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";
import { reducer as questionnaire } from "@zedoc/react-questionnaire";
import { clockReducer } from "../utils/clock";
import { reducer as stageReducer } from "./stage";
import { reducer as tokenReducer } from "./token";
import form from "./form";
import formBuilder from "./formBuilder";
import preferences from "./preferences";

const createReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    stage: stageReducer,
    token: tokenReducer,
    clock: clockReducer,
    questionnaire,
    form,
    formBuilder,
    preferences,
  });

export default createReducer;
