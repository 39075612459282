import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "localforage";
import { routerMiddleware } from "connected-react-router";
import { createMiddleware as createQuestionnaireMiddleware } from "./questionnaire";
import { createMiddleware as createStageMiddleware } from "./stage";
import { AuthError, createMiddleware as createTokenMiddleware } from "./token";
// import isDebug from '../utils/isDebug';
import { createNotifyError } from "../utils/notify";
import { clockMiddleware } from "../utils/clock";
import createReducer from "./createReducer";

export default ({ history, client, cachePersistor }) => {
  let persistor;

  const persistConfig = {
    storage,
    key: "zedoc",
    whitelist: ["storage", "stage", "preferences", "token"],
  };

  const flush = () => {
    return Promise.all([
      persistor ? persistor.flush() : Promise.resolve(),
      cachePersistor ? cachePersistor.persist() : Promise.resolve(),
    ]);
  };

  const purge = () => {
    return (
      persistor
        // First, we cleanup redux store, which include deleting all credentials.
        .purge()
        .then(() => {
          return Promise.all([
            // Next, we force ApolloClient to clean up its cache.
            cachePersistor.purge(),
            // In parallel, we use resetStore to force recomputing all queries.
            // After persistor.purge() they should all fail due to AuthError
            // and consequently DataProvider component will return empty data.
            client.resetStore().catch((err) => {
              if (
                err &&
                err.networkError &&
                err.networkError instanceof AuthError
              ) {
                return;
              }
              throw err;
            }),
          ]);
        })
        .catch(createNotifyError())
    );
  };

  const store = configureStore({
    reducer: persistReducer(persistConfig, createReducer(history)),
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
        thunk: {
          extraArgument: client,
        },
      }).concat([
        routerMiddleware(history),
        createQuestionnaireMiddleware({
          client,
        }),
        createStageMiddleware({
          client,
        }),
        createTokenMiddleware({
          flush,
          purge,
        }),
        clockMiddleware,
      ]);
    },
  });

  persistor = persistStore(store);

  if (process.env.NODE_ENV !== "production") {
    if (typeof module !== "undefined" && module.hot) {
      module.hot.accept("./createReducer", () => {
        // NOTE: We need to use persistor instead of store,
        //       because otherwise persist reducer will not
        //       have persist capabilities anymore.
        persistor.replaceReducer(
          persistReducer(
            persistConfig,
            // eslint-disable-next-line global-require
            require("./createReducer").default(history)
          )
        );
      });
    }
  }

  return {
    store,
    persistor,
  };
};
