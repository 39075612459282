import pick from "lodash/pick";
import { PURGE } from "redux-persist";
import {
  ACTION_LOGIN,
  ACTION_LOGOUT,
  ACTION_EXCHANGE,
  // ACTION_REFRESH,
  ACTION_UPDATE,
  ACTION_REPLACE,
} from "./actions";
import { CLOCK_ACTION } from "../../utils/clock";

const initialState = {
  authJwt: null,
  sessionJwt: null,
  refreshJwt: null,
  secret: null,
  lastActivityTs: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PURGE:
    case ACTION_LOGOUT:
      return initialState;
    case CLOCK_ACTION: {
      const { lastActivityTs } = action.meta || {};
      if (lastActivityTs) {
        return {
          ...state,
          lastActivityTs,
        };
      }
      return state;
    }
    case ACTION_LOGIN:
      return {
        ...initialState,
        ...pick(action.payload, ["authJwt", "secret", "lastActivityTs"]),
      };
    case ACTION_EXCHANGE: {
      return {
        ...state,
        secret: null,
      };
    }
    case ACTION_UPDATE:
      return {
        ...state,
        ...pick(action.payload, ["sessionJwt", "refreshJwt"]),
      };
    case ACTION_REPLACE: {
      return action.payload || {};
    }
    default:
      return state;
  }
};

export default reducer;
