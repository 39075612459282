export const IN_APP_CONTENT_TYPE__WELCOME = "welcome";
export const IN_APP_CONTENT_TYPE__FAQ = "FAQ";
export const IN_APP_CONTENT_TYPE__MEDICAL_INFORMATION = "medical_information";
export const IN_APP_CONTENT_TYPE__AUTHENTICATION = "authentication";
export const IN_APP_CONTENT_TYPES = [
  IN_APP_CONTENT_TYPE__WELCOME,
  IN_APP_CONTENT_TYPE__FAQ,
  IN_APP_CONTENT_TYPE__MEDICAL_INFORMATION,
  IN_APP_CONTENT_TYPE__AUTHENTICATION,
];
