import { useState, useEffect } from "react";

const useOnline = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useEffect(() => {
    const online = () => {
      setIsOnline(true);
    };
    window.addEventListener("online", online);
    return () => {
      window.removeEventListener("online", online);
    };
  });
  useEffect(() => {
    const offline = () => {
      setIsOnline(false);
    };
    window.addEventListener("offline", offline);
    return () => {
      window.removeEventListener("offline", offline);
    };
  });
  return isOnline;
};

export default useOnline;
