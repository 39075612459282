/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { push } from "connected-react-router";
import { encodeQuery } from "@zedoc/url";
import {
  logout,
  selectIsAuthenticating,
  selectIsLoggedIn,
} from "../store/token";
import Loading from "../components/Loading";
import settings from "../utils/settings";
import { getUserLanguage } from "../store/preferences";

const { patientAuthLoginUrl } = settings.public;

const LoggedInRoute = (props) => {
  const isAuthenticating = useSelector(selectIsAuthenticating);
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const dispatch = useDispatch();
  const language = useSelector(getUserLanguage);
  useEffect(() => {
    let canceled = false;
    if (!isAuthenticating && !isLoggedIn) {
      if (patientAuthLoginUrl) {
        // NOTE: We are using window.location instead of react-router-dom's
        //       history.push() because in most cases patientAuthLoginUrl
        //       will get the user outside of the app, e.g. the login page
        //       of custom authentication service. We call logout() again
        //       to ensure that state is written to local storage before redirecting.
        dispatch(logout()).then(() => {
          if (!canceled) {
            window.location = `${patientAuthLoginUrl}${encodeQuery({
              i18n: language,
              redirectUrl: window.location.href,
            })}`;
          }
        });
      } else {
        // NOTE: If there's no login URL, there's nothing we can do to help the user
        //       so we just redirect to the logged out page.
        dispatch(push("/logged-out"));
      }
    }
    return () => {
      canceled = true;
    };
  }, [isAuthenticating, isLoggedIn, patientAuthLoginUrl, dispatch, language]);

  if (isAuthenticating) {
    return <Loading />;
  }
  return <Route {...props} />;
};

export default LoggedInRoute;
