import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { ApolloClient, ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import Loading from "../components/Loading";
import Router from "../routes/Router";
import ThemeProvider from "./ThemeProvider";
import LanguageProvider from "./LanguageProvider";
import DataProvider from "./DataProvider";
import useLoading from "./useLoading";

const App = ({ store, history, client, promise }) => {
  const isLoading = useLoading(promise);

  if (isLoading) {
    return null;
  }

  return (
    <Suspense fallback={<Loading />}>
      <Provider store={store}>
        <ApolloProvider client={client}>
          <ThemeProvider>
            <LanguageProvider>
              <DataProvider>
                <Router history={history} />
              </DataProvider>
            </LanguageProvider>
          </ThemeProvider>
        </ApolloProvider>
      </Provider>
    </Suspense>
  );
};

App.propTypes = {
  store: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  promise: PropTypes.instanceOf(Promise),
  client: PropTypes.instanceOf(ApolloClient).isRequired,
};

App.defaultProps = {
  promise: null,
};

export default App;
